import React from 'react';
import clsx from 'clsx';
import {Link} from 'gatsby';
import {Event} from '../../../GAClickEvent';

import SwvlOne from '../../../../assets/images/landing/swvl/swvl-1.svg';
import SwvlTwo from '../../../../assets/images/landing/swvl/swvl-2.svg';
import SwvlThree from '../../../../assets/images/landing/swvl/swvl-3.svg';
import SwvlFour from '../../../../assets/images/landing/swvl/swvl-4.svg';
import SwvlFive from '../../../../assets/images/landing/swvl/swvl-5.svg';
import SwvlSix from '../../../../assets/images/landing/swvl/swvl-6.svg';

import * as classes from './Partnership.module.css';

const PartnershipSection = () => (
  <div className={classes.wrapper}>
    <div className={clsx(classes.container, 'flex-column')}>
      <h1 className={clsx(classes.sectionTitle, 'mb-3')}>Business Problem</h1>
      <p className={clsx(classes.description, 'mb-3')}>
        Swvl, the renowned global provider of transformative tech-enabled mass transit solutions and
        ridesharing services, was on an ambitious growth trajectory with expansion plans for the
        Middle East, Africa and Asia. They were looking to onboard technology solution providers
        that could augment their in-house product development team.
      </p>
    </div>
    <div className={clsx(classes.container, 'flex-column')}>
      <h1 className={clsx(classes.sectionTitle, 'mb-3')}>Swvl and Tintash Partnership</h1>
      <p className={clsx(classes.description, 'mb-3')}>
        Based on our Team Augmentation model, Tintash provided four separate engineering squads that
        worked on different problem sets for the client. The collaboration speeded up the
        implementation of new features and helped address Swvl’s emerging market needs promptly.
      </p>
    </div>
    <div className={clsx(classes.container, 'flex-column-reverse flex-lg-row')}>
      <div className={classes.imageContainer}>
        <img className={classes.image} src={SwvlOne} alt="design pro" />
      </div>
      <div className={clsx(classes.textContainer, 'pl-lg-5')}>
        <h2 className={classes.title}>MPesa</h2>
        <p className={clsx(classes.description)}>
          Swvl wanted to shift from cash to cashless payments in Kenya by integrating M-pesa into
          its app. Our team streamlined the process, minimizing changes and simplifying the payment
          flow, despite technical hurdles like asynchronous payment confirmation. We achieved a
          seamless transition to M-pesa within a tight timeframe, leading to an enhanced booking
          experience and a significant uptake in its adoption shortly after launch.
        </p>
      </div>
    </div>
    <div className={clsx(classes.container, 'flex-column flex-lg-row')}>
      <div className={clsx(classes.textContainer, 'pr-lg-5')}>
        <h2 className={classes.title}>EBANX</h2>
        <p className={clsx(classes.description)}>
          Swvl aimed to expand globally by integrating multiple local payment options into its app,
          crucial for reducing transaction fees compared to global gateways. This required
          decoupling the app from its single payment system and creating a configurable, data-driven
          payments module adaptable to local preferences. Our team redesigned the system for
          flexibility, incorporating EBANX for the Brazilian market and improving the website's user
          experience with card tokenization.
        </p>
      </div>
      <div className={classes.imageContainer}>
        <img className={classes.image} src={SwvlTwo} alt="design pro" />
      </div>
    </div>
    <div className={clsx(classes.container, 'flex-column-reverse flex-lg-row')}>
      <div className={classes.imageContainer}>
        <img className={classes.image} src={SwvlThree} alt="design pro" />
      </div>
      <div className={clsx(classes.textContainer, 'pl-lg-5')}>
        <h2 className={classes.title}>Refund to Source</h2>
        <p className={classes.description}>
          Swvl aimed to improve customer satisfaction by enabling refunds directly to the original
          payment source, instead of just in-app credits. Our team developed and integrated this
          feature, ensuring refunds could be processed back to bank accounts or credit cards. After
          successful beta testing, we rolled out the feature globally, enhancing the customer
          experience by facilitating direct refunds.
        </p>
      </div>
    </div>
    <div className={clsx(classes.container, 'flex-column flex-lg-row')}>
      <div className={clsx(classes.textContainer, 'pr-lg-5')}>
        <h2 className={classes.title}>Partners Portal</h2>

        <p className={clsx(classes.description)}>
          Our team developed a web-based vendor management portal for Swvl, enabling vendors to
          self-manage operations, reducing Swvl's reliance on operational staff and support. The
          initial MVP received positive feedback, leading to further enhancements aimed at improving
          usability and adoption rates. Despite design and component challenges, we met our
          deadlines, and vendor adoption grew. Swvl's product team is now actively training vendors
          to use the portal efficiently for their daily operations.
        </p>
      </div>
      <div className={classes.imageContainer}>
        <img className={classes.image} src={SwvlFour} alt="design pro" />
      </div>
    </div>
    <div className={clsx(classes.container, 'flex-column flex-lg-row')}>
      <div className={classes.imageContainer}>
        <img className={classes.image} src={SwvlFive} alt="design pro" />
      </div>
      <div className={clsx(classes.textContainer, 'pl-lg-5')}>
        <h2 className={classes.title}>Supply Pricing Automation</h2>

        <p className={clsx(classes.description)}>
          Our team automated Swvl's captain payment system, significantly reducing manual efforts
          and processing time. Initially, we unified scattered data sources, then digitized rate
          inputs into a single Rate Card system. Finally, we automated daily payout computations
          with a Cron Job, achieving a 98% accuracy rate at launch. This transformation into an
          automated framework saved thousands of man-hours, showcasing our ability to streamline and
          enhance operational efficiency across different regions with flexibility for timezone
          adjustments.
        </p>
      </div>
    </div>
    <div className={clsx(classes.container, 'flex-column flex-lg-row')}>
      <div className={clsx(classes.textContainer, 'pr-lg-5')}>
        <h2 className={classes.title}>Dispatcher and ETA Optimization</h2>

        <p className={clsx(classes.description)}>
          Our team significantly improved Swvl's arrival time accuracy by enhancing dispatcher
          monitoring, successfully deploying the machine learning-based Ratimator for ETA
          predictions, and consolidating various services into a single efficient API. This
          multifaceted approach not only streamlined operations but also reduced external API costs,
          demonstrating our expertise in technology and commitment to operational excellence.{' '}
        </p>
      </div>
      <div className={classes.imageContainer}>
        <img className={classes.image} src={SwvlSix} alt="design pro" />
      </div>
    </div>
    <div className={clsx(classes.buttonContainerMain)}>
      <div className={classes.buttonContainer}>
        <Link
          onClick={() => Event('Case Study', 'Case Study Button is clicked', 'Swvl Landing Page')}
          to="/portfolio/swvl"
        >
          <button type="button" className={classes.button}>
            Read Full Case Study
          </button>
        </Link>
      </div>

      <div className={classes.buttonContainer}>
        <Link
          onClick={() => Event('Portfolio', 'Portfolio button is clicked', 'Swvl Landing Page')}
          to="/portfolio"
        >
          <button type="button" className={classes.button2}>
            Explore Our Portfolio
          </button>
        </Link>
      </div>
    </div>
  </div>
);

export default PartnershipSection;
