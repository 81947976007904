import React from 'react';
import PropTypes from 'prop-types';
import * as classes from './Testimonial.module.css';

const TestimonialSection = ({titleLogoSrc, details, testimonial}) => (
  <div className={classes.wrapper}>
    <div className={classes.detailsContainer}>
      {titleLogoSrc && <img className={classes.titleImg} src={titleLogoSrc} alt="title" />}
      <ul className={classes.list}>
        {details.map(point => (
          <li className="mb-3" key={point}>
            {point}
          </li>
        ))}
      </ul>
    </div>
    <div className={classes.testimonialContainer}>
      <div className={classes.testimonial}>
        <p className={classes.quote}>{testimonial.quote}</p>
        <div className="">
          <div className="d-flex just-content-center">
            <div className="d-flex flex-column justify-content-center">
              <p className={classes.name}>{testimonial.name}</p>
              <p className={classes.position}>{testimonial.position}</p>
            </div>

            <img className={classes.image} src={testimonial.image} alt="client" />
          </div>
        </div>
      </div>
    </div>
  </div>
);

TestimonialSection.propTypes = {
  details: PropTypes.arrayOf(
    PropTypes.shape({
      point: PropTypes.string.isRequired,
    })
  ).isRequired,
  testimonial: PropTypes.shape({
    quote: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }).isRequired,
  themeColor: PropTypes.string.isRequired,
  titleLogoSrc: PropTypes.string,
};

TestimonialSection.defaultProps = {
  titleLogoSrc: null,
};

export default TestimonialSection;
