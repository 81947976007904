import React from 'react';
import {Link} from 'gatsby';
import {Event} from '../../../GAClickEvent';

import * as classes from './LandingFooter.module.css';

const LandingFooter = () => (
  <div className={classes.wrapper}>
    <p className={classes.text}>Let us guide you towards the start up journey of your dreams.</p>
    <Link className={classes.buttonContainer} to="/contact">
      <button
        onClick={() =>
          Event('Contact Us', 'Contact Us Button is clicked', 'Lifeprint Landing Page')
        }
        type="button"
        className={classes.button}
      >
        Get in touch
      </button>
    </Link>
  </div>
);

export default LandingFooter;
