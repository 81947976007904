import React from 'react';
import Helmet from 'react-helmet';

import LandingHeader from '../../../components/Landing/Section/Header';
import HeroSection from '../../../components/Landing/Section/Hero';
import TestimonialSection from '../../../components/Landing/Section/Testimonial';
import '../../../assets/css/landing.css';

import SwvlImage from '../../../assets/images/landing/swvl/swvl-section-image.svg';
import AboutSection from '../../../components/Landing/Section/About';
import ClientLogosSection from '../../../components/Landing/Section/ClientLogos/lndex';
import LandingFooter from '../../../components/Landing/Section/Footer';
import PartnershipSection from '../../../components/Landing/Section/PartnerShipFour';

const themeColor = '#1AA5F8';

const Hero = {
  title: (
    <div>
      <strong>Tech enabled mass transit solutions</strong>
    </div>
  ),
  subTitle:
    'Building the technology to offer intercity, intracity, B2B, and B2G transportation products and services.',
  bannerSrc: require('../../../assets/images/landing/swvl/swvl-banner.svg').default,
  logoSrc: require('../../../assets/images/landing/swvl/swvl-logo.svg').default,
};

const Testimonial = {
  titleLogoSrc: require('../../../assets/images/landing/swvl/tintash-x-swvl.svg').default,
  details: [
    <div>
      Our 4 squads worked independently to deliver results in
      <span className="text-blue"> 6 key areas</span>: M-pesa, EBANX, Refund to Source, Partners
      Portal, Supply Pricing, Dispatcher & ETA Optimization.
    </div>,
    <div>
      Each of these solutions helped solve a critical problem for Swvl. Supply pricing automation,
      for instance, helped
      <span className="text-blue"> save thousands of man hours</span> while achieving a
      <span className="text-blue"> 98% accuracy of results.</span>
    </div>,
  ],
  testimonial: {
    quote: (
      <div>
        Very proud of the team (Engineering and Product) that built a brand new product and
        microservice in a quarter! <br /> <br /> That is very impressive especially since they were
        all new to Swvl. Excited for what we are going to achieve in Q2 together. Keep up the great
        work!
      </div>
    ),

    name: 'Mostafa Zaher',
    position: (
      <div>
        Senior Engineering Manager
        <br /> Swvl Marketplaces
      </div>
    ),
    image: require('../../../assets/images/landing/swvl/swvl-client.svg').default,
  },
};

function Index() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Landing | Swvl</title>
      </Helmet>
      <div className="landing-wrapper">
        <LandingHeader />
        <HeroSection
          title={Hero.title}
          subTitle={Hero.subTitle}
          bannerSrc={Hero.bannerSrc}
          logoSrc={Hero.logoSrc}
        />
        <TestimonialSection
          titleLogoSrc={Testimonial.titleLogoSrc}
          details={Testimonial.details}
          testimonial={Testimonial.testimonial}
          themeColor={themeColor}
        />
        <img src={SwvlImage} alt="decorist" />
        <PartnershipSection />
        <AboutSection />
        <ClientLogosSection />
        <LandingFooter />
      </div>
    </>
  );
}

export default Index;
