// extracted by mini-css-extract-plugin
export var detailsContainer = "Testimonial-module--detailsContainer--SLBtE";
export var image = "Testimonial-module--image--jsTiP";
export var list = "Testimonial-module--list--MZDFf";
export var name = "Testimonial-module--name--nIGU1";
export var position = "Testimonial-module--position--oS-UR";
export var quote = "Testimonial-module--quote--Mxv2z";
export var testimonial = "Testimonial-module--testimonial--c-rUv";
export var testimonialContainer = "Testimonial-module--testimonialContainer--fLuKW";
export var titleImg = "Testimonial-module--titleImg--gVHcf";
export var wrapper = "Testimonial-module--wrapper--8xomv";