import React from 'react';
import PropTypes from 'prop-types';
import * as classes from './Hero.module.css';

const HeroSection = ({title, subTitle, bannerSrc, logoSrc, type}) => (
  <div className={classes.heroWrapper}>
    <div className={classes.bannerContainer}>
      <img
        className={classes.banner}
        src={bannerSrc}
        alt="banner"
        style={{width: type === 'enhanced' ? '90%' : '60%'}}
      />
    </div>
    <div className={classes.titleContainer}>
      <img className={classes.logo} src={logoSrc} alt="client logo" />
      <div className={classes.title}>{title}</div>
      <div className={classes.subTitle}>{subTitle}</div>
    </div>
  </div>
);

HeroSection.propTypes = {
  title: PropTypes.string.isRequired,
  bannerSrc: PropTypes.string.isRequired,
  logoSrc: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  type: PropTypes.string,
};

HeroSection.defaultProps = {
  type: 'basic',
};

export default HeroSection;
