// extracted by mini-css-extract-plugin
export var button = "Partnership-module--button--Bq7Hu";
export var button2 = "Partnership-module--button2--ce3Og";
export var buttonContainer = "Partnership-module--buttonContainer--mYbi3";
export var buttonContainerMain = "Partnership-module--buttonContainerMain--9Kt7T";
export var container = "Partnership-module--container--JaCFq";
export var description = "Partnership-module--description--yaCKy";
export var image = "Partnership-module--image--zLK-P";
export var imageContainer = "Partnership-module--imageContainer--9XpRW";
export var sectionTitle = "Partnership-module--sectionTitle--9iItj";
export var textContainer = "Partnership-module--textContainer--iL7CD";
export var title = "Partnership-module--title--CSjKS";
export var wrapper = "Partnership-module--wrapper--Y1Bv7";